const menu = () => {
  const menuButton = document.querySelector('.js-nav-toggle');
  const nav = document.querySelector('.js-nav');

  menuButton.addEventListener('click', event => {
    event.preventDefault();

    menuButton.classList.toggle('-expanded');
    nav.classList.toggle('-expanded');
  });
};

export default menu();
